/* Generated by Font Squirrel (http://www.fontsquirrel.com) on January 29, 2013 01:11:33 PM America/New_York */


@font-face {
    font-family: 'OpenSansLight';
    src: url('../fonts/opensans-light-webfont.eot');
    src: url('../fonts/opensans-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-light-webfont.woff') format('woff'),
         url('../fonts/opensans-light-webfont.ttf') format('truetype'),
         url('../fonts/opensans-light-webfont.svg#OpenSansLight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansRegular';
    src: url('../fonts/opensans-regular-webfont.eot');
    src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-regular-webfont.woff') format('woff'),
         url('../fonts/opensans-regular-webfont.ttf') format('truetype'),
         url('../fonts/opensans-regular-webfont.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansSemibold';
    src: url('../fonts/opensans-semibold-webfont.eot');
    src: url('../fonts/opensans-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-semibold-webfont.woff') format('woff'),
         url('../fonts/opensans-semibold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-semibold-webfont.svg#OpenSansSemibold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'OpenSansBold';
    src: url('../fonts/opensans-bold-webfont.eot');
    src: url('../fonts/opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/opensans-bold-webfont.woff') format('woff'),
         url('../fonts/opensans-bold-webfont.ttf') format('truetype'),
         url('../fonts/opensans-bold-webfont.svg#OpenSansBold') format('svg');
    font-weight: normal;
    font-style: normal;

}