@import "bower_components/font-awesome/scss/font-awesome";
@import "dist/vendor/opensans/css/stylesheet";

$dark-bg: #18151f;
$dark-bg-color: #f1f1f1;

$header-color: #7d94af;

// -----  bootstrap variables -----
$icon-font-path: "/fonts/";

$font-family-sans-serif:  "Open Sans", sans-serif;
$font-size-base: 16px;

$font-size-h1: $font-size-base * 2.5;
$font-size-h2: $font-size-base * 2;
$headings-font-weight: bold;

$text-color: #101010;

$btn-primary-color: #000;
$btn-primary-bg: $header-color;

$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;

$grid-float-breakpoint: $screen-md-min; // un-collapse navbar starting from here

$grid-gutter-width: 30px;
$container-sm: $screen-sm-min - 48px + $grid-gutter-width;
$container-md: $screen-md-min - 52px + $grid-gutter-width;
$container-lg: $screen-lg-min - 60px + $grid-gutter-width;

$navbar-default-bg: $dark-bg;
$navbar-default-link-color: $dark-bg-color;
$navbar-default-link-active-color: $dark-bg-color;
$navbar-default-link-active-bg: lighten($dark-bg, 5);
$navbar-default-link-hover-color: $dark-bg-color;
$navbar-default-link-hover-bg: darken($dark-bg, 100);
$navbar-default-toggle-hover-bg: darken(#688BB3, 30);

@import "bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

@mixin breakpoint($class) {
    @if $class == xs {
        @media (max-width: 767px) { @content; }
    }

    @else if $class == sm {
        @media (min-width: 768px) { @content; }
    }

    @else if $class == md {
        @media (min-width: 992px) { @content; }
    }

    @else if $class == lg {
        @media (min-width: 1200px) { @content; }
    }

    @else {
        @warn "Breakpoint mixin supports: xs, sm, md, lg";
    }
}

body {
    background-color: #eee;
}

img.masthead {
    display: block;
    margin: 0 auto;
    max-width: 100%;
}

p, .lead, .h1, .h2, .h3, h1, h2, h3 {
    margin-bottom: .6em;
}

.h1, h1 {
    margin-top: 0.6em;
}

.h2, .h3, h2, h3 {
    margin-top: 1.2em;
}

// anchor offset
h2:before {
    content:"";
    display:block;
    height: 82px;
    margin: -82px 0 0;
}

hr {
    border-bottom: 1px #AAA dashed;
    margin: 3em 0;
}

.cta-button {
    margin: 9px 0 20px;
    display:inline-block;
}

// ---------------------------- Navbar ----------------------------
body.menupadding {
    padding-top: $navbar-height;
    header {
        margin-bottom: 22px;
    }
    @include breakpoint("md") {
        padding-top: 80px;
        header {
            margin-bottom: 22px;
        }
    }
}
.navbar {
    border-radius: 0 !important;
}

@include breakpoint("md") {
    .navbar {
        min-height: 80px;
    }
    .navbar-nav {
        $navbar-padding-vertical: ((80px - $line-height-computed) / 2);
        > li {
            float: left;
            > a {
                padding-top:    $navbar-padding-vertical;
                padding-bottom: $navbar-padding-vertical;
            }
        }
    }
    #navbar {
        padding-left: 0;
        padding-right: 0;
    }
}

// ----------------------------- Header ----------------------------
header {
    background-color: $header-color;
    img#header-logo {

        margin: 2em 0;
        width: 100%;
        display: block;
        @include breakpoint("md") {
            margin: 3em 0;
        }
    }
}

// --------------------------- Top Link ----------------------------
#top-link-block {

    a {
        background-color: $header-color;
        color: #000;
        border: none;
    }

    &.affix-top {
        position: absolute; /* allows it to "slide" up into view */
        bottom: -82px; /* negative of the offset - height of link element */
        left: 10px; /* padding from the left side of the window */
    }

    &.affix {
        position: fixed; /* keeps it on the bottom once in view */
        bottom: 18px; /* height of link element */
        left: 10px; /* padding from the left side of the window */
    }
}

// ----------------------------- Footer ----------------------------
footer {
	background-color: $dark-bg;
    color: rgba(255,255,255,.6);
    margin-top: 4rem;
	padding-top: 5em;
    padding-bottom: 5em;
    text-align: center;

    #socialmedia {
        cursor: default;
        padding-left: 0;
        font-size: 1.5em;
        list-style: none;
        margin: 1em 0 0em;
        li {
            display: inline-block;
            line-height: 2;
            margin: 0 .5em;
            a {
                color: rgba(255, 255, 255, .5);
                border-bottom: none;
                transition: color 0.2s ease-in-out, color 0.2s ease-in-out;
                &:hover {
                    text-decoration: none;
                    color: rgba(255, 255, 255, .7);
                }
                &:before {
                    font-family: FontAwesome;
                    font-weight: 400;
                }
                span {
                    display: none;
                }
            }
        }
    }
}

// ---------------------------- Frontpage ----------------------------
.front-panel {
    margin-top: 1.1em;
    margin-bottom: 0;
    border: 2px solid gray;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    min-width: 350px;

    .panel-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;

        &::after, &::before {
            content: '';
            display: none;
        }
        a {
            display: block;
        }

        a.btn {
            align-self: flex-end;
        }
    }

    img {
        display: block;
        width: 100%;
        margin-bottom: .8em;
    }
}

@include breakpoint("md") {
    .panel-row {
        display: flex;
        .col-md-6 {
            display: flex;
            align-items: stretch;
        }
    }
}

.front-quote {
    margin: 2em 0;
}

.contributors {
    margin-bottom: 0;
}

// ---------------------------- Features ----------------------------
.btn {
    i.fa-github {
        font-size: 18px;
    }
}

// ---------------------------- News ----------------------------
.news-entry {
    .date {
        border-left: 3px solid gray;
        padding: 1em;
        margin-bottom: 1em;
    }
}

.news-youtube {
    margin: 1em 0;
    max-width: 500px;
}


// ----------------------------- Publications ----------------------------
#publications {
    .x-small {
        font-size: 0.75em;
    }
    h2.x-small{
        margin-top: 4em;
        font-size: 1em;
    }
    li {
        margin-bottom: .5em;
    }

    p.x-small {
        margin-bottom: 1em;
    }
}

ul.pub-list,
ol.pub-list {
    li {
        img {
            vertical-align: baseline;
            margin-bottom: -1px;
            border: none;
        }
    }
}


// ---------------------------- Tutorials ----------------------------

.graphs {
    margin-top: 3em;
    img {
        margin: 0 auto 1em;
        display: block;
        max-width: 450px;
        width: 100%;
    }
}

// ----------------------------- Data ----------------------------
.data {
    #map {
        width: 100%;
        height: 400px;
        margin: 1em auto;

        .info.legend {
            padding: 1em;
            background-color: #eee;
            color: #000;
            border-radius: 7px;
            font-size: $font-size-h5;

            i {
                width: 1em;
                height: 1em;
                margin-right: 1em;
                border-radius: 3px;
                float: left;
                dispay: inline-block;
            }
        }
    }

    .photos {

        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -webkit-align-items: center;
        align-items: center;

        //margin-top: 1em;
        margin-bottom: 1em;

        .photo {
            margin: 0 1em 1em 0;
            img {
                width: auto;
                max-height: 180px;
            }
        }
    }
}
